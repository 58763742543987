body, html {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("../public/texture.png"); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  /* background-color: whitesmoke; */
  height: 100%;
}

#root {
  height: 100vh;
}

p {
  font-size: 110%;
}

