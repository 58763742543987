.card-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
}


.btn-sm {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  padding: 2px 0px 0 2px;
}

.btn-md {
  width: 50px;
  height: 50px;
  padding-top: 10px;
  border-radius: 25px;
  text-align: center;
}

.btn-xl {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  text-align: center;
  padding: 4px 0px 0 0px;
}

.icon-sm {
  width: 30px;
  height: 30px;
}

.icon-md {
  width: 50px;
  height: 50px;
}

.icon-xl {
  width: 70px;
  height: 70px;
}

.notification {
  height: fit-content;
  width: fit-content;
  text-align: center;
  padding: 5px 5px;
  margin: 5px;
  border-radius: 5px;
  position: relative;
}

.notification>a {
  color: purple;
}

.rememberme {
  margin: 5px 5px;
}

.center-vert {
  position: relative;
  top: 15rem;
  right: 10rem;
}

.rotate {
  animation-name: spin;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 650px) {

  .flex-center,
  .home-action {
    margin-top: 2%;
  }

  .carousel-card {
    left: 10%;
  }

  .card{
    width: 20px;
  }

}

@media screen and (min-width: 300) {
  .card{
    width: 100%;
  }

}

@media screen and (max-width: 1000px) {

  .carousel-card {
    left: 20%;
  }

  .center-vert {
    position: inherit;
  }
}

@keyframes spin {
  0% {
    transform: scale(1.0);
  }

  100% {
    transform: scale(2.0);
  }
}