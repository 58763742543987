.carousel-img {
    width: 1050px;
    height: 500px;
}

.card-img-top{
    width: 320px;
    height: 100px;
}

.textured{
  /* background-image: url("../../public/texture.png"); */
}